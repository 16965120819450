export default function changeSelect(category) {
    const selectPrefix = '.js-select-';
    const selectClass = selectPrefix + category;
    const selectElement = document.querySelector(selectClass);

    if (!selectElement) {
        return;
    }

    const {
        location: {
            href,
            origin,
            pathname,
        },
    } = window;

    const theUrl = href;
    const baseUrl = origin;
    const trailingSlash = '/';
    const categoryTag = trailingSlash + category + trailingSlash;
    const cleanPath = pathname.replace(/\/$/, '');
    const urlSegments = cleanPath.split('/');
    const lastSegment = urlSegments.pop();
    let setOption = baseUrl + categoryTag + lastSegment + trailingSlash;

    if (theUrl === baseUrl + categoryTag) {
        setOption = baseUrl + trailingSlash + lastSegment + trailingSlash;
    }
    selectElement.value = setOption;
}
window.addEventListener('load', changeSelect('mediendaten'));
window.addEventListener('load', changeSelect('faq'));
window.addEventListener('load', changeSelect('events'));
